/* eslint-disable no-unused-vars */
import feathers from '@feathersjs/client';
import rest from '@feathersjs/rest-client';

import Config from './config';
import isEmpty from '../modules/isEmpty';

const client = feathers();
const restClient = rest(Config.serverRest);
client.configure(restClient.fetch(window.fetch));

client.configure(feathers.authentication({
	storage: window.localStorage,
}));

export default {
	// authenticate, return user if successfull
	async authenticate() {
		try {
			return await client.authenticate();
		} catch (ex) {
			return null;
		}
	},
	async logout() {
		try {
			return await client.logout({strategy: 'local'});
		} catch (ex) {
			return null;
		}
	},
	async login(name, password) {
		try {
			return await client.reAuthenticate();
		} catch (error) {
			// do noting
		}

		try {
			return await client.authenticate({
				strategy: 'local',
				name,
				password,
			});
		} catch (error) {
			console.log(error);
			return null;
		}
	},
	// competitions
	async insertCompetition(fields) {
		return await client.service('competitions').create(fields);
	},
	async updateCompetition(id, fields) {
		return await client.service('competitions').update(id, {
			fields,
		});
	},
	async getCompetitions(query = {}, $sort, withEvents = false) {
		if (isEmpty($sort) === true) {
			$sort = {
				start: 1,
			};
		}
		return await client.service('competitions').find({
			query: Object.assign(query, {
				_withEvents: withEvents,
				$sort,
			})
		});
	},
	async deleteCompetition(id) {
		return await client.service('competitions').remove(id);
	},
	async getCompetition(id, withEvents = false) {
		return await client.service('competitions').get(id, {
			query: {
				_withEvents: withEvents,
			}
		});
	},
	// schedules
	async getSchedules($sort) {
		if (isEmpty($sort) === true) {
			$sort = {
//				date: 1,
			};
		}
		return await client.service('schedules').find({
			query: {
				$sort,
			}
		});
	},
	async getSchedule(id) {
		return await client.service('schedules').get(id);
	},
	// events
	async getEvents($sort) {
		if (isEmpty($sort) === true) {
			$sort = {
//				date: 1,
			};
		}
		return await client.service('events').find({
			query: {
				$sort,
			}
		});
	},
	async getEvent(id) {
		return await client.service('events').get(id);
	},
	// combineds
	async getCombined(id) {
		return await client.service('combineds').get(id);
	},
	async domainsFind(query = {}) {
		return await client.service('domains').find({
			query: Object.assign(query, {
				$sort: {
					id: 1,
				},
			}),
		});
	},
	async domainGet(id) {
		return await client.service('domains').get(id);
	},
	async domainUpdate(id, domain) {
		return await client.service('domains').patch(id, domain);
	},
	async domainDelete(id) {
		return await client.service('domains').remove(id);
	},
	async domainInsert(id) {
		return await client.service('domains').create({id});
	},
	// competitors
	async competitorsFind(query = {}) {

		return await client.service('competitors').find({
			query: Object.assign(query, {
				$sort: {
					id: 1,
				},
			}),
		});
	},
}
