import formatDate from '../modules/formatDate';
import formatTime from '../modules/formatTime';
import isEmpty from '../modules/isEmpty';
import Stats from '../modules/stats';

import API from '../components/api';
import FfaEvent from '../components/ffa-event';
import FFAEventComponentPDF from '../components/ffa-event-component-pdf';

export default async function CompetitionsModule() {

	return {
		template: require('/src/pages/competitions.html'),
		components: {
			FfaEvent,
			FFAEventComponentPDF,
		},
		watch: {
			async '$route.params.scheduleId'() {
				await this.getEvents();
			},
			async '$reloadKey.count'() {
				//console.log(this.$route.params.scheduleId, this.$reloadKey.message.id, this.$route.params.scheduleId === this.$reloadKey.message.id);

				// refresh competition
				if (this.$reloadKey.message.type === 'competitions' && this.$route.params.id === this.$reloadKey.message.id) {
					await this.getCompetition(this.$route.params.id);
				}
				// refresh events
				if (this.$reloadKey.message.type === 'events') {
					const eventIds = this.events.map(e => e.id);
					if (eventIds.includes(this.$reloadKey.message.id) === true) {
						await this.getEvents(true);
					}
				}
				if (this.$reloadKey.message.type === 'refresh') {
					await this.getEvents(true);
					this.$reloadKey.status = 'connected';
				}
			},
		},
		data() {
			return {
				competition: null,
				competitionLoading: false,
				competitionError: null,
				events: [],
			};
		},
		computed: {
			quotesSorted() {
				return [...this.competition.quotes].reverse();
			},
		},
		async created() {
			if (this.isModeIframe() === true) {
				document.body.className = 'bg-transparent';
			} else {
				document.body.className = 'bg-athle';
			}
			//console.log('created!', this.$route.params.id, this.$route.params.scheduleId);

			if (isEmpty(this.$route.params.id) === true) {
				this.$router.push('/')
				return;
			}

			await this.getCompetition(this.$route.params.id);
			if (this.competition === null) {
				if (this.competitionError === null) {
					this.competitionError = 'Compétition invalide';
				}
				return;
			}

			if (this.$route.params.scheduleId === 'quotes') {
				return;
			}

			if (isEmpty(this.$route.params.scheduleId) === true) {
				// find best day to display
				let scheduleIdx = 0;
				for (let idx of Object.keys(this.competition.schedules)) {
					const schedule = this.competition.schedules[idx];
					if (formatDate(schedule.date) === formatDate(new Date())) {
						scheduleIdx = idx;
					}
				}
				this.$router.replace('/competitions/' + this.competition.id + '/' + this.competition.schedules[scheduleIdx].id);
			} else {
				await this.getEvents();
			}

			const carouselControlNextEl = this.$refs['carousel-control-next'];
			if (isEmpty(carouselControlNextEl) === false) {
				carouselControlNextEl.click();
			}
		},
		methods: {
			formatDate,
			formatTime,
			async getCompetition(id) {
				this.competitionLoading = true;
				this.competitionError = null;

				try {
					this.competition = await API.getCompetition(id, true);
				} catch (ex) {
					if (ex.message === 'Failed to fetch') {
						this.competitionError = 'Serveur déconnecté';
					} else {
						this.competitionError = ex.message;
					}
				} finally {
					this.competitionLoading = false;
				}
			},
			async getEvents(refreshCompetition = false) {
				//console.log('getEvents!', this.$route.name, this.$route.params);

				if (this.$route.name !== 'competitions') {
					return;
				}
				if (isEmpty(this.$route.params.scheduleId) === true) {
					return;
				}

				if (this.$route.params.scheduleId === 'quotes') {
					return;
				}

				if (refreshCompetition === true) {
					await this.getCompetition(this.$route.params.id);
				}

				const eventsIds = this.competition.schedules.map((a) => a.id);

				document.title = this.competition.name + ' - ' + formatDate(this.competition.schedules.find((a) => a.id === this.$route.params.scheduleId).date, false, '.');
				Stats.trackPage(this.$route.fullPath);

				if (eventsIds.includes(this.$route.params.scheduleId) === true) {
					this.events = this.competition.schedules[eventsIds.indexOf(this.$route.params.scheduleId)].events;

					// progress / startlist / scheduled / official / finished
					this.events.sort((a, b) => {
//						if (a.status === 'In Progress') {
//							return -5;
//						}
//						if (b.status === 'In Progress') {
//							return 5;
//						}
//						if (a.status === 'Startlist') {
//							return -4;
//						}
//						if (b.status === 'Startlist') {
//							return 4;
//						}
//						if (a.status === 'Scheduled') {
//							return -3;
//						}
//						if (b.status === 'Scheduled') {
//							return 3;
//						}
//						if (a.status === 'Official') {
//							return -2;
//						}
//						if (b.status === 'Official') {
//							return 2;
//						}
//						if (a.status === 'Finished') {
//							return -1;
//						}
//						if (b.status === 'Finished') {
//							return 1;
//						}
						if (a.date < b.date) {
							return -1;
						}
						if (a.date > b.date) {
							return 1;
						}
						return 0;
					});
				} else {
					// error
					console.error("error");
			}
			},
			onPlayerOpen(ev) {
				const iframeEl = ev.currentTarget.previousElementSibling.childNodes[1];
				const miniplayerEl = ev.currentTarget.previousElementSibling;

				miniplayerEl.classList.remove('d-none');

				for (let el of this.$el.querySelectorAll('.open-button')) {
					el.classList.add('d-none');
				}

				iframeEl.src = iframeEl.getAttribute('data-src');
			},
			onPlayerClose(ev) {
				const iframeEl = ev.currentTarget.parentNode.nextElementSibling;
				const miniplayerEl = ev.currentTarget.parentNode.parentNode;

				miniplayerEl.classList.add('d-none');

				for (let el of this.$el.querySelectorAll('.open-button')) {
					el.classList.remove('d-none');
				}

				iframeEl.src = '';
			},
			formatRichText(blocks) {
				const htmlArray = blocks.elements.map((block) => {
					let html = '';

					if (block.style && block.style.bold === true) {
						html += '<strong>';
					}
					switch (block.type) {
						case 'link':
							html += `<a href="${block.url}" target="_blank">` + block.text.replace('\n', '<br>') + '</a>';
							break;
						case 'text':
							html += block.text.replace(/\n/g, '<br>');
							break;
						case 'emoji':
							html += String.fromCodePoint(parseInt(block.unicode, 16));
							break;
						default:
							console.error('unknown block type', block);
							break;
					}
					if (block.style && block.style.bold === true) {
						html += '</strong>';
					}

					return html;
				});

				return htmlArray.join('');
			},
		}
	};
}
