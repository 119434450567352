import API from '../components/api';
import isEmpty from '../modules/isEmpty';

export default async function HomeModule() {

	return {
		template: require('/src/pages/login.html'),
		data() {
			return {
				loginLoading: false,
				loginError: null,
			};
		},
		async created() {
			const authUser = await API.authenticate();
 
			if (authUser !== null && typeof authUser.user === 'object' && authUser.user !== null) {
				this.$authUser.user = authUser.user;
				return this.$router.push('/backoffice');
			}
		},
		methods: {
			async onLogin(ev) {
				if (isEmpty(ev.currentTarget.name.value) === true) {
					this.loginError = 'Invalid login';
					return;
				}
				if (isEmpty(ev.currentTarget.password.value) === true) {
					this.loginError = 'Invalid password';
					return;
				}

				this.loginLoading = true;
				this.loginError = null;

				try {
					const login = await API.login(ev.currentTarget.name.value, ev.currentTarget.password.value);
					//console.log('login:', login);

					if (login === null) {
						this.loginError = 'Invalid credentials';
					} else {
						return this.$router.push('/backoffice');
					}
				} catch (ex) {
					this.loginError = ex.message;
				} finally {
					this.loginLoading = false;
				}
			},
		}
	};
}
