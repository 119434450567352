import API from '../components/api';
import Config from '../components/config';
import routerLinkExternal from '../components/routerLinkExternal';
import formatDate from '../modules/formatDate';
import isEmpty from '../modules/isEmpty';
import t from '../modules/translations';
import Stats from '../modules/stats';

const dateDiffInDays = (date1, date2) => {
	const milliseconds = (new Date(date1) - new Date(date2));
	const seconds = milliseconds / 1000;
	return seconds / 60 / 60 / 24;
};

export default async function HomeModule() {

	return {
		template: require('/src/pages/home.html'),
		components: {
			routerLinkExternal,
		},
		watch: {
			async '$reloadKey.count'() {
				if (this.$reloadKey.message.type === 'competitions') {
					await this.getCompetitions();
				}
				if (this.$reloadKey.message.type === 'refresh') {
					await this.getCompetitions();
					this.$reloadKey.status = 'connected';
				}
			},
		},
		data() {
			return {
				competitions: null,
				competitionsAll: null,
				competitionLoading: false,
				competitionError: null,
				search: null,
				filterActive: 'all',
			};
		},
		async created() {
			await this.getCompetitions();

			if (this.isModeIframe() === true) {
				document.body.className = 'bg-transparent';
			} else {
				document.body.className = 'bg-home';
			}
			document.title = t('Résultats Athlétisme LIVE');
			Stats.trackPage(this.$route.fullPath);
		},
		computed: {
			competitionsYears() {
				if (this.competitionsAll !== null) {
					let y = this.competitionsAll.map(c => c.start.split('-')[0]);
					y = y.filter((cY) => cY !== undefined);
					// uniq
					return [...new Set(y)].sort();
				}
				return [];
			},
		},
		methods: {
			formatDate,
			async getCompetitions() {
				this.competitionLoading = true;
				this.competitionError = null;

				try {
					this.competitionsAll = await API.getCompetitions({$domains: this.$domain.config.filters});
					this.competitions = this.competitionsAll;

					if (this.competitionsYears.includes(String(new Date().getFullYear())) === true) {
						this.filterActive = String(new Date().getFullYear());
					} else {
						this.filterActive = String(this.competitionsYears[0]);
					}

					this._updateFiltersCompetitions();
				} catch (ex) {
					if (ex.message === 'Failed to fetch') {
						this.competitionError = 'Serveur déconnecté';
					} else {
						this.competitionError = ex.message;
					}
				} finally {
					this.competitionLoading = false;
				}
			},
			onSearchUpdate(ev) {
				this.search = ev.currentTarget.value.toLowerCase();

				this.filterActive = 'all';

				this._updateFiltersCompetitions();
			},
			_updateFiltersCompetitions() {
				if (isEmpty(this.search) === false) {
					this.competitions = this.competitionsAll.filter((c) => (c.name.toLowerCase().search(this.search) >= 0));
				} else {
					this.onDisplayFiltered(this.filterActive);
				}
			},
			getCompetitionHost(domains) {
				if (Config.isProd === true && domains.includes('FFA') === true && domains.length === 1) {
					return 'https://athle-liveresults.fr';
				}
				return '';
			},
			onDisplayFiltered(filter) {
				//console.log('onDisplayFiltered:', filter);

				switch (filter) {
					case 'all':
						this.competitions = this.competitionsAll;
						break;
					case 'passed':
						this.competitions = this.competitionsAll.filter((c) => {
							const diff = dateDiffInDays(c.start, new Date());
							return diff >= -4 && diff < -1;
						});
						break;
					case 'next':
						this.competitions = this.competitionsAll.filter((c) => {
							const diff = dateDiffInDays(c.start, new Date());
							return diff >= -1 && diff < 4;
						});
						break;
					default:
						//console.log('filter per year:', filter);
						this.competitions = this.competitionsAll.filter((c) => {
							return new Date(c.start).getFullYear() === Number(filter);
						});
						break;
				}
				this.filterActive = filter;
			},
		}
	};
}
