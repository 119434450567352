
module.exports = (date) => {
	
	date = new Date(date);
	
	const dateOut = [
		('0' + date.getHours()).slice(-2),
		('0' + date.getMinutes()).slice(-2),
	];
	
	const finalDate = dateOut.join(':');
	return finalDate.trim();
};
