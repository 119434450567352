import API from '../components/api';

const domainsConfig = {};

const getDomainConfig = async() => {
	//console.log('getDomainConfig:', window.location.origin);

	const domain = window.location.origin.replace(/^https?:\/\//, '').split(':')[0];
	if (typeof domainsConfig[domain] !== 'undefined') {
		return domainsConfig[domain];
	}
	
	const configs = await API.domainsFind({domain});

	if (configs.length === 1) {
		domainsConfig[domain] = configs[0];
		return domainsConfig[domain];
	}
	return null;
};

export default async () => {
	return await getDomainConfig();	
}
