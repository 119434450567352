
export default  {
	template: require('/src/components/ffa-event-component-pdf.html'),
	props: {
		documents: Object,
		fullSize: Boolean,
	},
	data() {
		return {
		};
	},
}
