import formatDate from '../modules/formatDate';
import formatTime from '../modules/formatTime';
import statusColor from '../modules/statusColor';

export default {
	name: 'ffa-event',
	template: require('/src/components/ffa-event.html'),
	props: ['event'],
	methods: {
		formatDate,
		formatTime,
		getColor(status) {
			return statusColor(status);
		},
	},
}
