
module.exports = (status) => {
	switch (status) {
		case 'Scheduled':
			return '#0000ff'; // blue
		case 'Startlist':
			return '#ffa500'; // orange
		case 'In Progress':
			return '#008000'; // green
		case 'Official':
			return '#ff0000'; // red
		case 'Finished':
			return '#000000'; // black
	}
	return '#000000'; // black
};
