import {reactive} from 'vue';

let pageVisibile = true;
document.addEventListener('visibilitychange', () => {
	pageVisibile = (document.visibilityState === 'visible');
});

export default (globalProperties, domainConfig) => {

	globalProperties.count = 0;
	globalProperties.status = 'connected';
	globalProperties.message = {type: 'refresh'};
	globalProperties.$reloadKey = reactive({
		count: globalProperties.count,
		status: globalProperties.status,
		message: globalProperties.message,
	});

	const delay = domainConfig.liveDelay || 10;

	const onRefrehAction = () => {
		if (pageVisibile === false) {
			return;
		}
		globalProperties.$reloadKey.status = 'connecting';
		globalProperties.$reloadKey.count++;
	};

	let timerId = 0;

	globalProperties.onPageChange = () => {
		window.clearInterval(timerId);
		timerId = window.setInterval(onRefrehAction, delay * 1000);
	};

	globalProperties.onPageChange();
};
