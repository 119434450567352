
module.exports = (type) => {
	const types = {
		WL: 'World Lead',
		EL: 'Europe Lead',
		WR: 'World Record',
		PB: 'Personal Best',
		SB: 'Season Best',
		MR: 'Meet Record',
		AR: 'Area record',
		CR: 'Championship record',
		GR: 'Games record',
		NR: 'National record',
		OR: 'Olympic record',
		ER: 'European Record',
		DLR: 'Diamond League Record',
		WYB: 'World Under 18 Best',
		WB: 'World Best',
		NB: 'National Best',
	};
	return (typeof types[type] === 'string' ? types[type] : type);
};
