
module.exports = (date, withYear = true, sep = '/') => {
	
	date = new Date(date);
	
	const dateOut = [
		('0' + date.getDate()).slice(-2),
		('0' + (date.getMonth() + 1)).slice(-2),
	];
	
	if (withYear === true) {
		dateOut.push(('' + date.getFullYear()).slice(-4));
	}

	const finalDate = dateOut.slice(0, 3).join(sep) + ' ' + dateOut.slice(3).join(':');
	return finalDate.trim();
};

