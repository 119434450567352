import {createApp} from 'vue/dist/vue.esm-bundler';
import {createRouter, createWebHistory} from 'vue-router';
import {reactive} from 'vue';
import isEmpty from './modules/isEmpty'
import getLegend from './modules/getLegend';
import translations from './modules/translations';
import Config from './components/config';

import Stats from './modules/stats';

// modules
import RealTime from './modules/realtime';
import Refresh from './modules/refresh';
import Domains from './modules/domains';
// components
import Header from './components/header';
import BOHeader from './components/bo_header';
import Footer from './components/footer';
import Loader from './components/loader';
import Flag from './components/flag';
import CompetitionStatus from './components/competition-status';
import Records from './components/records';
import SlackFile from './components/slackFile';
// pages
import Home from './pages/home';
import Login from './pages/login';
import Competitions from './pages/competitions';
import Events from './pages/events';
import BOHome from './pages/backoffice/home';
import BODomains from './pages/backoffice/domains';
import BOCompetitors from './pages/backoffice/competitors';
import BOLive from './pages/backoffice/live';

const setFavicon = (rel, href, size = null) => {
	const headTitle = document.querySelector('head');

	const setAFavicon = document.createElement('link');
	setAFavicon.setAttribute('rel', rel);
	if (size !== null) {
		setAFavicon.setAttribute('sizes', size);
		setAFavicon.setAttribute('type', 'image/png');
	}
	setAFavicon.setAttribute('href', href);
	headTitle.appendChild(setAFavicon);
};

const routes = [
	{path: '/', component: Home, name: 'home'},
	{path: '/competitions/:id/:scheduleId?', component: Competitions, name: 'competitions', meta: {transition: 'slide-right'}},
	{path: '/events/:id/:tab?', component: Events, name: 'events', meta: {transition: 'slide-left'}},
	// backoffce
	{path: '/login', component: Login, name: 'login'},
	{path: '/backoffice', component: BOHome, name: 'backoffice'},
	{path: '/backoffice/domains/:id?', component: BODomains, name: 'domains'},
	{path: '/backoffice/competitors/:id?', component: BOCompetitors, name: 'competitors'},
	{path: '/backoffice/live/:id?', component: BOLive, name: 'live'},
];

const router = createRouter({
	history: createWebHistory(),
	routes,
});

const app = createApp();

app.config.globalProperties.$authUser = reactive({
	user: null,
});
app.config.globalProperties.$domain = reactive({
	config: null,
});
app.config.globalProperties.onPageChange = () => {
};
app.config.globalProperties.$athlete = reactive({
	athlete: null,
});

const capitalize = (string) => {
	const capitalized = [];
	string.split(' ').forEach(word => {
		capitalized.push(
				word.charAt(0).toUpperCase() +
				word.slice(1).toLowerCase()
				);
	});
	return capitalized.join(' ');
};

app.config.globalProperties.$capitalize = capitalize;

app.config.globalProperties.isEmpty = isEmpty;
app.config.globalProperties.getLegend = getLegend;
app.config.globalProperties.t = translations;
app.config.globalProperties.Config = Config;

app.use(router);

router.afterEach((to, from) => {
	if (typeof to.meta.transition === 'undefined') {
		const toDepth = to.path.split('/').length;
		const fromDepth = from.path.split('/').length;
		to.meta.transition = toDepth < fromDepth ? 'slide-right' : 'slide-left';
	}
	app.config.globalProperties.onPageChange();
});

app.component('ffa-header', Header());
app.component('ffa-bo-header', BOHeader());
app.component('ffa-footer', Footer());
app.component('ffa-loader', Loader());
app.component('ffa-flag', Flag());
app.component('ffa-competition-status', CompetitionStatus());
app.component('ffa-records', Records());
app.component('ffa-slack-file', SlackFile());

const updateCSS = () => {
	if (isEmpty(app.config.globalProperties.$domain.config.colorPrimary) === true) {
		return;
	}

	for (let stylesheet of document.styleSheets) {
		if (stylesheet.href && stylesheet.href.endsWith('theme.css') === true) {
			for (let rule of stylesheet.cssRules) {
				// check liveffa.scss
				if (rule.selectorText === '.btn-primary') {
					rule.style.setProperty('background-color', app.config.globalProperties.$domain.config.colorPrimary, 'important');
				}
				if (rule.selectorText === 'footer') {
					rule.style.setProperty('background-color', app.config.globalProperties.$domain.config.colorPrimary, 'important');
				}
				if (rule.selectorText === '.bg-domain-primary') {
					rule.style.setProperty('background-color', app.config.globalProperties.$domain.config.colorPrimary, 'important');
				}
				if (rule.selectorText === '.border-color-domain-primary') {
					rule.style.setProperty('border-color', app.config.globalProperties.$domain.config.colorPrimary, 'important');
				}
				if (rule.selectorText === '.color-domain-primary') {
					rule.style.setProperty('color', app.config.globalProperties.$domain.config.colorPrimary, 'important');
				}
				if (rule.selectorText === '#page-home .competitions-filters ul.list-filters li.active .badge') {
					rule.style.setProperty('background-color', app.config.globalProperties.$domain.config.colorPrimary, 'important');
				}
				if (rule.selectorText === '#page-event .table tbody tr') {
					rule.style.setProperty('border-bottom', `4px solid ${app.config.globalProperties.$domain.config.colorPrimary}36`, '');
				}
			}
		}
	}

};

(async () => {
	const domainConfig = await Domains();

	if (domainConfig === null) {
		console.error('invalid domain');
		return;
	}

	switch (domainConfig.domain) {
		case 'athle-liveresults.fr':
			document.querySelector('meta[name="description"]').content = 'Retrouvez sur notre site tous les résultats FFA des meetings et championnats d’athlétisme';
			domainConfig.root = 'athle';
			domainConfig.matomoId = '3';
			break;
		case 'athle.matsport.com':
			document.querySelector('meta[name="description"]').content = 'Retrouvez sur notre site tous les résultats des meetings et championnats d’athlétisme by Matsport';
			domainConfig.root = 'matsport';
			domainConfig.matomoId = '2';
			break;
		case 'athletv.athle-liveresults.fr':
			document.querySelector('meta[name="description"]').content = 'Retrouvez sur notre site tous les résultats FFA des meetings et championnats d’athlétisme';
			domainConfig.root = 'athle';
			domainConfig.matomoId = '1';
			break;
		case 'athletv.matsport.com':
			document.querySelector('meta[name="description"]').content = 'Retrouvez sur notre site tous les résultats des meetings et championnats d’athlétisme by Matsport';
			domainConfig.root = 'matsport';
			domainConfig.matomoId = '';
			break;
		default:
			document.querySelector('meta[name="description"]').content = 'Retrouvez sur notre site tous les résultats des meetings et championnats d’athlétisme';
			domainConfig.root = 'matsport';
			domainConfig.matomoId = '';
			break;
	}

	setFavicon('apple-touch-icon', `/icons/${domainConfig.root}/apple-touch-icon.png`, '180x180');
	setFavicon('icon', `/icons/${domainConfig.root}/favicon-32x32.png`, '32x32');
	setFavicon('icon', `/icons/${domainConfig.root}/favicon-16x16.png`, '16x16');
	setFavicon('manifest', `/icons/${domainConfig.root}/site.webmanifest`);

	app.config.globalProperties.$domain.config = domainConfig;

	app.config.globalProperties.isModeIframe = () => {
		// no config
		if (app.config.globalProperties.$domain.config === null) {
			return false;
		}
		if (app.config.globalProperties.$domain.config.iframe === true) {
			return true;
		}
		return false;
	};

	if (domainConfig.matomoId !== '') {
		Stats.init(domainConfig.matomoId, app.config.globalProperties.isModeIframe());
	}

	updateCSS();

	if (domainConfig.liveType === 'refresh') {
		Refresh(app.config.globalProperties, domainConfig);
	} else {
		RealTime(app.config.globalProperties);
	}

	// lauch app
	app.mount('#app');

})();
