
export default function CompetitionStatusModule() {
	return {
		template: `<span class="badge bg-domain-primary"><i :class="(forceText === true ? '' : 'me-sm-1') + ' ' + getStatusIcon(status)"></i>&nbsp;<span :class="(forceText === true ? '' : 'd-none d-sm-inline')">{{t(status)}}</span></span>`,
		props: {
			status: String,
			forceText: Boolean,
		},
		methods: {
			getStatusIcon(status) {
				switch (status) {
					case 'STARTLIST':
						return 'far fa-rectangle-list';
					case 'SCHEDULED':
						return 'far fa-clock';
					case 'RUNNING':
						return 'fas fa-running';
					case 'OFFICIAL':
						return 'fas fa-flag-checkered';
					case 'FINISHED':
						return 'fas fa-medal';
				}
				return '';
			},
		},
	};
}
