import isEmpty from '../modules/isEmpty';

import FFAEventComponentResults from '../components/ffa-event-component-results';
import FFAEventComponentStartlist from '../components/ffa-event-component-startlist';
import FFAEventComponentRanking from '../components/ffa-event-component-ranking';
import FFAEventComponentPDF from '../components/ffa-event-component-pdf';

export default {
	name: 'ffa-event-track',
	template: require('/src/components/ffa-event-track.html'),
	props: ['event'],
	components: {
		FFAEventComponentResults,
		FFAEventComponentStartlist,
		FFAEventComponentRanking,
		FFAEventComponentPDF,
	},
	watch: {
		event() {
			this.onCreated();
		},
	},
	data() {
		return {
		};
	},
	computed: {
		records() {
			return this.event.records.sort((a, b) => {
				if (a.result < b.result) {
					return -1;
				}
				if (a.result > b.result) {
					return 1;
				}
				return 0;
			});
		},
		eventSortedLane() {
			const results = this.event.trackResult.results.map((r) => {
				if (r.rank === 999) {
					r.rank = '';
				}
				return r;
			});
			return results.sort((a, b) => parseInt(a.lane) - parseInt(b.lane));
		},
		eventSortedRank() {
			const eventSortedRank = [...this.event.trackResult.results].sort((a, b) => a.displayRank - b.displayRank);
			return eventSortedRank.filter(r => (r.status !== 'NO_RESULT'));
		},
		tab() {
			return this.$route.params.tab;
		},
	},
	created() {
		this.onCreated();
	},
	methods: {
		onCreated() {
			if (isEmpty(this.$route.params.tab) === true) {
				let defaultTab = isEmpty(this.eventSortedRank) === false ? '/results' : '/startlist';
				if (isEmpty(this.event.engaged) === false && isEmpty(this.eventSortedLane) === true) {
					defaultTab = '/engaged';
				}
				this.$router.replace('/events/' + this.$route.params.id + defaultTab);
			}
		},
		changeTab(tab) {
			this.$router.replace('/events/' + this.$route.params.id + '/' + tab);
		},
		hasCol(col) {
			return isEmpty(
					this.event.trackResult.results.find(r => (isEmpty(r[col]) === false))
					) === false;
		},
	},
}
