import Config from './config';

export default function HeaderModule() {

	return {
		template: require('/src/components/header.html'),
		data() {
			return {
				Config,
			};
		},
	};
}
