import Config from './config';

export default function LoaderModule() {

	return {
		template: '<div class="text-center loader"><i class="fas fa-2x fa-spinner animation-rotation"></i></div>',
		data() {
			return {
				Config,
			};
		},
	};
}
