
const isProd = ['athle.matsport.com', 'athletv.matsport.com', 'athle-liveresults.fr', 'www.athle-liveresults.fr', 'athletv.athle-liveresults.fr'].includes(document.domain) === true;
const isTest = (document.domain === 'test.athle.matsport.com' || document.domain === 'test.athletv.matsport.com');

export default Object.freeze({
	isProd,
	appName: 'LiveFFA',
//	timeout: 10000,
	serverRest: (
			isProd === true ? 'https://api.athle.matsport.com' : (isTest === true ? 'https://test-api.athle.matsport.com' : 'http://localhost:3030')
			),
});
