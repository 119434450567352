import API from '../../components/api';

export default async function BackOfficeCompetitorsModule() {

	return {
		template: require('/src/pages/backoffice/competitors.html'),
		data() {
			return {
				competitors: null,
			};
		},
		async created() {
		},
		methods: {
			async onSearchUpdate(ev) {
				const search = ev.currentTarget.value.toLowerCase();

				const query = {
					longName: {
						$search: '(?i).*' + search + '.*',
					}
				};

				this.competitors = await API.competitorsFind(query);
			},
		},
	};
}
