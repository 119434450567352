const countryCodes = require('country-code-info');

export default function FlagModule() {

	return {
		template: `<span v-if="getA3Code() !== null"><img :src="'/flags/' + getA3Code() + '.png'" style="height: 17px; border-radius: 15px 0 15px 0;" :title="country"></span>`,
		props: {
			country: String,
		},
		data() {
			return {
			};
		},
		methods: {
			getA3Code() {
				if (this.country === 'ANA') {
					return this.country;
				}
				const c = countryCodes.findCountry({'ioc': this.country});
				if (c && typeof c.ioc === "string" && c.ioc.length > 0) {
					return c.ioc.toUpperCase();
				}
				return null;
			}
		},
	};
}
