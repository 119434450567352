
export default function SlackFileModule() {
	return {
		template: require('/src/components/slackFile.html'),
		props: {
			file: Object,
		},
		data() {
			return {
			};
		},
		methods: {
		},
	};
}
