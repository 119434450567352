import isEmpty from '../modules/isEmpty';

import FFAEventComponentRider from '../components/ffa-event-component-rider';

export default {
	template: require('/src/components/ffa-event-component-startlist.html'),
	props: {
		eventSortedLane: Object,
		eventType: String,
	},
	components: {
		FFAEventComponentRider,
	},
	data() {
		return {
		};
	},
	methods: {
		getTypeLane() {
			switch (this.eventType) {
				// Course
				case 'A':
				case 'B':
					return 'Couloir';
					// Marche Marathon
				case 'C':
				case 'D':
					return 'Ordre';
					// Concours
				case 'E':
				case 'F':
				case 'G':
					return 'Classement';
			}
			return '';
		},
		hasCol(col) {
			return isEmpty(
					this.eventSortedLane.find(r => (isEmpty(r[col]) === false))
					) === false;
		},
	},
}
