
module.exports = (data) => {
	if (data === null) {
		return true;
	}
	if (typeof data === 'undefined') {
		return true;
	}
	if (typeof data.length === 'number' && data.length === 0) {
		return true;
	}
	return false;
};
