
export default {
	template: require('/src/components/ffa-event-component-rider.html'),
	props: {
		athlete: Object,
		idx: Number,
	},
	data() {
		return {
		};
	},
	methods: {
		openRiderModal(athlete, ev) {
			//console.log('openRiderModal!', athlete);

			ev.preventDefault();
			ev.stopPropagation();

			this.$athlete.athlete = athlete;

			const modal = new window.mdb.Modal(document.getElementById('modal-rider'));
			modal.show();
		},
	},
}
