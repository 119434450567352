import Config from './config';
import API from '../components/api';

export default function HeaderModule() {

	return {
		template: require('/src/components/bo_header.html'),
		data() {
			return {
				Config,
				logoutLoading: false,
				logoutError: null,
			};
		},
		async created() {
			const authUser = await API.authenticate();

			if (authUser === null) {
				return this.$router.push('/login');
			}

			if (typeof authUser.user !== 'object' || authUser.user === null) {
				return this.$router.push('/login');
			}

			//console.log('User is authenticated', authUser);
			this.$authUser.user = authUser.user;
		},
		methods: {
			async onLogout() {
				this.logoutLoading = true;
				this.logoutError = null;

				try {
					const logout = await API.logout();
					//console.log('logout:', logout);

					if (logout === null) {
						this.logoutError = 'Error';
					} else {
						return this.$router.push('/');
					}
				} catch (ex) {
					this.logoutError = ex.message;
				} finally {
					this.logoutLoading = false;
				}
			},
		},
	};
}
