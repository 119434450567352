
export default async function BackOfficeHomeModule() {

	return {
		template: require('/src/pages/backoffice/home.html'),
		data() {
			return {
				links: {
					domains: {
						name: 'Domaines',
						icon: 'landmark-dome',
					},
					competitors: {
						name: 'Athlètes',
						icon: 'person-running',
					},
					live: {
						name: 'Live',
						icon: 'retweet',
					},
				},
			};
		},
		async created() {
			document.body.className = 'bg-white';
		},
	};
}
