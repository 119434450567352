import isEmpty from '../modules/isEmpty';

import FFAEventComponentRider from '../components/ffa-event-component-rider';

export default {
	template: require('/src/components/ffa-event-component-live.html'),
	props: {
		currentItem: Object,
		previousItem: Object,
		eventType: String,
	},
	components: {
		FFAEventComponentRider,
	},
	data() {
		return {
		};
	},
	methods: {
		getAttemptName() {
			switch (this.eventType) {
				// Course
				case 'A':
				case 'B':
					return '';
					// Marche Marathon
				case 'C':
				case 'D':
					return '';
					// Concours
				case 'E':
				case 'G':
					return 'round_Attempt';
				case 'F':
					return 'ht_Att1';
			}
		},
		lastAttempt(attempts) {
			let lastAttempt = {};

			for (let attempt of attempts) {
				if (isEmpty(attempt[this.getAttemptName()]) === false) { // isEmpty(attempt.ht) === false  (case F)
					lastAttempt = attempt;
				}
			}
			return lastAttempt;
		},
		lastNextAttempt(attempts) {
			let lastAttempt = {};

			for (let attempt of attempts) {
				lastAttempt = attempt;
			}
			return lastAttempt;
		},
		getResultsName() {
			switch (this.eventType) {
				// Course
				case 'A':
				case 'B':
					return '';
					// Marche Marathon
				case 'C':
				case 'D':
					return 'walkResult';
					// Concours
				case 'E':
				case 'G':
					return 'attemptHorizontal';
				case 'F':
					return 'attemptVertical';
			}
		},
		coloredAttempt(attemptValue) {
			switch (attemptValue) {
				case 'X':
					return '<span class="text-red">' + attemptValue + '</span>';
				case 'O':
					return '<span class="text-green">' + attemptValue + '</span>';
				case '-':
					return '<span class="text-grey">' + attemptValue + '</span>';
			}
			return attemptValue;
		},
		displayAttempts(attempt) {
			let out = '';
			if (isEmpty(attempt.round_Attempt) === false) {
				out += this.coloredAttempt(attempt.round_Attempt);
			}
			if (isEmpty(attempt.ht_Att1) === false) {
				out += this.coloredAttempt(attempt.ht_Att1);
			}
			if (isEmpty(attempt.ht_Att2) === false) {
				out += this.coloredAttempt(attempt.ht_Att2);
			}
			if (isEmpty(attempt.ht_Att3) === false) {
				out += this.coloredAttempt(attempt.ht_Att3);
			}
			
			if (out !== '') {
				out = '(' + out + ')';
			}
			return out;
		},
	},
}
