import API from '../components/api';
import FfaEventTrack from '../components/ffa-event-track';
import FfaEventHcontest from '../components/ffa-event-hcontest';
import FfaEventVcontest from '../components/ffa-event-vcontest';
import FfaEventWalk from '../components/ffa-event-walk';

import Stats from '../modules/stats';

export default async function EventsModule() {

	return {
		template: require('/src/pages/events.html'),
		components: {
			FfaEventTrack,
			FfaEventHcontest,
			FfaEventVcontest,
			FfaEventWalk,
		},
		watch: {
			async '$route.params.id'() {
				if (this.$route.name === 'events') {
					await this.getEvent(this.$route.params.id);
				}
			},
			async '$route.params.tab'() {
				if (this.event !== null) {
					document.title = this.event.event + ' - ' + this.event.phase + (this.$route.params.tab ? ' - ' + this.$route.params.tab : '');
				}
				Stats.trackPage(this.$route.fullPath);
			},
			async '$reloadKey.count'() {
				if (this.$route.params.id === this.$reloadKey.message.id) {
					//console.log('refresh!', this.$route.params.id, this.$reloadKey.count, this.$reloadKey.message.id);
					await this.getEvent(this.$route.params.id);
				}
				if (this.$reloadKey.message.type === 'refresh') {
					await this.getEvent(this.$route.params.id);
					this.$reloadKey.status = 'connected';
				}
			},
		},
		data() {
			return {
				event: null,
				eventLoading: false,
				eventError: null,
			};
		},
		async created() {
			if (this.isModeIframe() === true) {
				document.body.className = 'bg-transparent';
			} else {
				document.body.className = 'bg-athle';
			}

			if (this.$route.params.id.length > 0) {
				await this.getEvent(this.$route.params.id);
			} else {
				this.$router.push('/')
			}
		},
		methods: {
			async getEvent(id) {
				this.eventLoading = true;
				this.eventError = null;

				try {
					this.event = await API.getEvent(id);
				} catch (ex) {
					this.event = null;
					if (ex.message === 'Failed to fetch') {
						this.eventError = 'Serveur déconnecté';
					} else {
						this.eventError = ex.message;
					}
				} finally {
					this.eventLoading = false;
				}
			},
		}
	};
}
