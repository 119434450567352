import isEmpty from '../modules/isEmpty';

import FFAEventComponentRider from '../components/ffa-event-component-rider';

export default {
	template: require('/src/components/ffa-event-component-results.html'),
	props: {
		ranks: Object,
		eventType: String,
		eventStatus: String,
		hasCol: Function,
	},
	components: {
		FFAEventComponentRider,
	},
	data() {
		return {
		};
	},
	methods: {
		getAttemptName() {
			switch (this.eventType) {
				// Course
				case 'A':
				case 'B':
					return '';
					// Marche Marathon
				case 'C':
				case 'D':
					return '';
					// Concours
				case 'E':
				case 'G':
					return 'round_Attempt';
				case 'F':
					return 'ht_Att1';
			}
		},
		getAttemptResultName() {
			switch (this.eventType) {
				// Course
				case 'A':
				case 'B':
					return '';
					// Marche Marathon
				case 'C':
				case 'D':
					return '';
					// Concours
				case 'E':
				case 'G':
					return 'round_Attempt';
				case 'F':
					return 'ht';
			}
		},
		getResultsName() {
			switch (this.eventType) {
				// Course
				case 'A':
				case 'B':
					return 'splits';
					// Marche Marathon
				case 'C':
				case 'D':
					return 'walkResult';
					// Concours
				case 'E':
				case 'G':
					return 'attemptHorizontal';
				case 'F':
					return 'attemptVertical';
			}
		},
		getTypeLane() {
			switch (this.eventType) {
				// Course
				case 'A':
				case 'B':
					return 'Couloir';
					// Marche Marathon
				case 'C':
				case 'D':
					return 'Ordre';
					// Concours
				case 'E':
				case 'F':
				case 'G':
					return 'Classement';
			}
			return '';
		},
		getLineCurrentPrevColor(item) {
			// dev only
//			if (item.bib === '7') {
//				item.current = 'X';
//				item.athlete.club = 'EA CERGY PONTOISE ATHLETISME *';
//			}
//			if (item.bib === '6') {
//				item.previous = 'X';
//			}

			if (item.current === 'X') {
				return 'isCurrent';
			}
			if (item.previous === 'X') {
				return 'isPrevious';
			}
			return '';
		},
		countCols() {
			let counter = 6;
			if (this.hasCol('result') === false) {
				counter--;
			}
			if (this.hasCol('points') === false) {
				counter--;
			}
			if (this.hasCol('time') === false) {
				counter--;
			}
			return counter;
		},
		coloredAttemptClass(attemptValue) {
			switch (attemptValue) {
				case 'X':
					return 'fail';
				case 'O':
					return 'success';
				case '-':
					return 'skip';
			}
			return '';
		},
		toggleDisplayNext(ev) {
			const el = ev.currentTarget;

			el.nextElementSibling.classList.toggle('d-none');

			const toggleArrowEl = el.querySelector('[data-ref=toggle-arrow]');
			if (toggleArrowEl !== null) {
				toggleArrowEl.classList.toggle('fa-angle-up');
			}
		},
		toggleDisplayCurrent(ev) {
			const el = ev.currentTarget;

			el.parentNode.classList.toggle('d-none');
			
			const toggleArrowEl = el.parentNode.previousElementSibling.querySelector('[data-ref=toggle-arrow]');
			if (toggleArrowEl !== null) {
				toggleArrowEl.classList.toggle('fa-angle-up');
			}
		},
		hasToggleDisplay(item) {
			return isEmpty(item[this.getResultsName()]) === false;
		},
	},
}
