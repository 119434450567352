import API from '../../components/api';
import isEmpty from '../../modules/isEmpty';

export default async function LiveHomeModule() {

	return {
		template: require('/src/pages/backoffice/live.html'),
		watch: {
			async '$route.params.id'() {
				if (this.$route.name === 'live') {
					await this.onCreated();
				}
			},
		},
		data() {
			return {
				domainsError: null,
				domainsLoading: false,
				domains: null,
				domain: null,
			};
		},
		async created() {
			document.body.className = 'bg-white';

			await this.onCreated();
		},
		methods: {
			async onCreated() {
				this.domains = null;
				this.domain = null;

				await this.domainsFind();

				if (isEmpty(this.$route.params.id) === false) {
					await this.domainGet(this.$route.params.id);
				}
			},
			async domainsFind() {
				this.domainsLoading = true;
				this.domainsError = null;
				try {
					this.domains = await API.domainsFind();
				} catch (ex) {
					this.domainsError = ex.message;
				} finally {
					this.domainsLoading = false;
				}
			},
			onDomainChange(ev) {
				this.$router.push('/backoffice/live/' + ev.currentTarget.value);
			},
			async domainGet(id) {
				this.domainsLoading = true;
				this.domainsError = null;

				try {
					this.domain = await API.domainGet(id);

					this.domain.liveType = this.domain.liveType || 'live';
					this.domain.liveDelay = this.domain.liveDelay || 10;
				} catch (ex) {
					this.domainsError = ex.message;
				} finally {
					this.domainsLoading = false;
				}
			},
			async onDomainUpdate(ev) {

				const liveType = ev.currentTarget['liveType'].value;
				const obj = {
					liveType
				};
				if (liveType === 'refresh') {
					obj.liveDelay = parseInt(ev.currentTarget['liveDelay'].value);
				}
				try {
					await API.domainUpdate(this.domain.id, obj);

					this.$router.push('/backoffice/live');
				} catch (ex) {
					console.error(ex);
				}
			},
		},
	};
}
