
export default function RecordsModule() {
	return {
		template: require('/src/components/records.html'),
		props: {
			records: Object,
		},
		data() {
			return {
			};
		},
		methods: {
			openRecord(ev) {
				for (let contentEl of ev.currentTarget.querySelectorAll('.chip-content')) {
					contentEl.classList.toggle('d-none');
				}
			},
		},
	};
}
