import API from '../../components/api';
import isEmpty from '../../modules/isEmpty';

export default async function BackOfficeDomainsModule() {

	return {
		template: require('/src/pages/backoffice/domains.html'),
		watch: {
			async '$route.params.id'() {
				if (this.$route.name === 'domains') {
					await this.onCreated();
				}
			},
		},
		data() {
			return {
				domainsError: null,
				domainsLoading: false,
				domains: null,
				domain: null,
				filterNames: [
					'MATSPORT', 'FFA', 'AURA',
				],
				domainUpdateError: null,
				domainUpdateLoading: false,
				domainInsertError: null,
				domainInsertLoading: false,
			};
		},
		async created() {
			await this.onCreated();
		},
		methods: {
			async onCreated() {
				this.domains = null;
				this.domain = null;

				if (isEmpty(this.$route.params.id) === false) {
					await this.domainGet(this.$route.params.id);
				} else {
					await this.domainsFind();
				}
			},
			async domainsFind() {
				this.domainsLoading = true;
				this.domainsError = null;
				try {
					this.domains = await API.domainsFind();
				} catch (ex) {
					this.domainsError = ex.message;
				} finally {
					this.domainsLoading = false;
				}
			},
			async domainGet(id) {
				this.domainsLoading = true;
				this.domainsError = null;

				try {
					this.domain = await API.domainGet(id);
				} catch (ex) {
					this.domainsError = ex.message;
				} finally {
					this.domainsLoading = false;
				}
			},
			async onDomainUpdate(ev) {
				try {
					const filters = {};

					for (let f of this.filterNames) {
						filters[f] = ev.currentTarget['form-filters-' + f].checked === true;
					}

					const domain = {
						id: this.domain.id,
						domain: ev.currentTarget['form-domain'].value,
						iframe: ev.currentTarget['form-iframe'].checked === true,
						filters,
						colorPrimary: ev.currentTarget['form-color-primary'].value,
					}

					await this.domainUpdate(domain);
				} catch (ex) {
					console.error(ex);
				}
			},
			async onDomainInsert(ev) {
				//console.log('onDomainInsert!', ev.currentTarget);

				this.domainInsertLoading = true;
				this.domainInsertError = null;

				try {
					const name = ev.currentTarget['form-name'].value;
					if (isEmpty(name) === true) {
						this.domainInsertError = 'Invalid name';
						return;
					}
					const newDomain = await API.domainInsert(name);
					if (newDomain === null) {
						this.domainInsertError = 'Could not add new domain';
						return;
					}
					return this.$router.push('/backoffice/domains/' + name);
				} catch (ex) {
					this.domainInsertError = ex.message;
				} finally {
					this.domainInsertLoading = false;
				}
			},
			async domainUpdate(domain) {
				this.domainUpdateLoading = true;
				this.domainUpdateError = null;

				try {
					await API.domainUpdate(domain.id, domain);

					return this.$router.push('/backoffice/domains');
				} catch (ex) {
					this.domainUpdateError = ex.message;
				} finally {
					this.domainUpdateLoading = false;
				}
			},
			async onDeleteDomain(id) {
				this.domainUpdateLoading = true;
				this.domainUpdateError = null;

				try {
					await API.domainDelete(id);

					this.domains = null;
					this.domain = null;
					await this.domainsFind();
				} catch (ex) {
					this.domainUpdateError = ex.message;
				} finally {
					this.domainUpdateLoading = false;
				}
			},
		},
	};
}
